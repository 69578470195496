
export const useWriteSelector = () => {
  const router = useRouter()
  const route = useRoute()

  return {
    isOpen: computed(() => {
      return route.query.escrita === '1'
    }),
    open() {
      // Push to same route, but add query parameter
      router.push({
        ...route,
        query: { 
          ...route.query,
          escrita: 1
        }
      })
    },
    close() {
      // Remove query parameter
      if (route.query.escrita === undefined) return

      router.push({
        ...route,
        query: { 
          ...route.query,
          escrita: undefined
        }
      })
    }
  }
}